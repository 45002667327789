import "../scss/app.scss";
import Vue from "vue";
import "bootstrap";
import VueScrollClass from "vue-scroll-class";
const carousels = require("./carousels");
import Datepicker from "vuejs-datepicker";

const app = new Vue({
  el: "#app",
  data: {
    isToggleMenu: false,
    sellFlow: "a",
    searchData: {
      estate_cat: "tochi",
      norm: "estate_area",
    },
  },
  components: {
    Datepicker,
  },
  mounted() {
    carousels.carouselSingle();
    carousels.carouselSingleThumbnail();
    carousels.carouselCentering();
    carousels.carouselSideScrolling();
    carousels.carouselCover();
  },
  methods: {
    estateCatChange(e, data) {
      this.searchData.estate_cat = data;
      this.searchData.norm = "estate_area";
      //カレントタブが見える位置に
      const boxTab = document.getElementById("boxTab");
      const item = document.getElementById(e.target.id);
      boxTab.scrollLeft = item.getBoundingClientRect().x;
    },
    normCatChange(data) {
      this.searchData.norm = data;
    },
    // scrollSlide(e) {
    //   const boxTab = document.getElementById("boxTab");
    //   const item = document.getElementById(e.target.id);
    //   console.log(e);
    //   console.log(item.getBoundingClientRect());
    //   boxTab.scrollLeft = item.getBoundingClientRect().x;
    // },
    check(e) {
      console.log(e);
      const item = document.getElementById(e.target.id);
      console.log(item);
      item.classList.toggle("active");
    },
  },

  directives: { "scroll-class": VueScrollClass },
});
